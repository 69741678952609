<template>
  <div class="index">
    <div class="header" :class="[{ 'active-nav': isScroll },{ 'active-nav': aboutUs }]">
      <div class="head">
        <div class="nav">
          <ul class="nav-wrap">
            <li>
              <a
                href="/"
                aria-current="page"
                :class="{'active' : isactive==0}"
                >首页</a
              >
            </li>
            <li class="s-n-item"><a href="/introduction" :class="{'active' : isactive==1}">企业介绍</a></li>
            <li class="product-server">
              <a :class="{'active' : isactive==2}">产品与服务</a>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAA/UlEQVQ4T92TvS5EURSFvy8qiQegm1JFVEKjU4hEoZT4KXmEURtvQOmn0CkkotBpiEqodHQ8gEQlS0buyHXN3Ewx1Zz2nP3ts9daWwZ0HBCHYQcluQdegDX1qx/dkowAZ0BDnf3RKMkjMAUcqjt9gg6AbeBJne6AGsAdMA401f06WJImsAe8A3Pq669rSWaAG2AM2FRPu8GSrAPHwAewoD603/2xP8kicFkAltXrMqzu/l+OkmwAR9WOlR9vqSflJl0DWdLgDZgvCm6BCWBXbVXH7pnsJB1XnouiyTpX60DtnJwDKwXoAljtlbPaXUsyClwVoCX1s1cshnhpvwEHPlcTB/jKTgAAAABJRU5ErkJggg=="
                alt
                class="down-arrow show-active"
              />
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAABOElEQVQ4T92TO0sDQRSFv8vuBgQhk067lFa+NiCxsbMQwcJS8FHqT4i1+g+09FHYWQhiYWdjELK+KjvttMsEBCGz4cpGCUlMYgqrTHvnfnfmnHOFfzryTxwGHRSY8BaRF1curQC1PnXzgkx4ipJ1NpqpaxSY8BEYRzlwlWirH1CQDvcRNoEnZ6OJb7HNZNZXryjCiAqFuBzt9YL5memCqOyo8h5LLY99eG24FpjclKLXAsMg686WTjrBApNbBT1S+BBkztnSfXKvxX4/Hc4LXCQFhcW4El01w3rVf+UoMOEacNg+sfXFbDgbHTcP6RjIhgbwFlObTRp8vBuBURXdjst3u+3f7prshivKc71JGOvlaq8V8VImPFNY+hHzvGqj5W45+2PX8kOBqV4mIGdTC1D87BaLAV7aL2uqchMXxHOkAAAAAElFTkSuQmCC"
                alt
                class="down-arrow hide-active"
              />
              <ul class="pssubnav">
                 <li><a href="/index#youshi">我们的优势</a></li>
              <li><a href="/index#product">产品功能</a></li>
              <li><a href="/index#serve">服务理念</a></li>
              </ul>
            </li>
            <li><a href="/news" :class="{'active' : isactive==3}">行业动态</a></li>
            <li><a href="/aboutus" :class="{'active' : isactive==4}">关于我们</a></li>
          </ul>
        </div>
        <div class="mobile-menu">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAcCAYAAAAJKR1YAAABDUlEQVRYR+2XoUpEQRiFvw8Ug8GwVQwKIhYXDPoIJovN4EP4Aj6Fj2ETBJN9QbBsU5DFIBgMgkHcX0a8y2Dwbtk7K8xfB+Z8nP9yzh2Zs7HhiYhz4BhY6JjxE7gETtTxN1BErAGPHYP8lttVbxugRWAA7BSCekja6lu+siVgG0hwXU5a2VB9T6IToC4J/tKqQG2bqA5Vh9ocaDvPc+gAOCpUHVfqxSSHIqIHPAEpHEvMGNhU75vqWPkBWi5BA3wAG+ooX9kecFhoZdfqTa2OaT6HmtRtLlWH/q9DEZHCcb/QL+xAfcmrI1XGEFhvs3RG58/AlvraVMcqMJqR2LTX9tW7vDrOSj4U1dO5rI4v3KNHHc6rw/UAAAAASUVORK5CYII="
            alt
            class="show-active"
          />
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAcCAYAAAAJKR1YAAABXklEQVRYR+2XsUrDUBSG/5P2mkE0aV3FQUXEwYoR9RGcXNw6+BC+gE/hY7gVBCc3hzRWl2wKUjqIUhIUit7ce6TUpnWQ2yVphWQ9cP/v/vfc898QZuyjIY9wvAsQ6gDKOTMqAA0ZBacA9ACo4q0IxnPOIL/lGJ6Mg7sfhzwhXPgAalOCepKiV8Nr+JEeGbBuC9fdgobIFYq0krEdAre9vu4YUK4Yf4oVQKZzKBwqHDI5YKqnPWRXd4+0ppNpRAexvvqKW5ejObSwvySspAMi27SDbOqsLaaNzzh4HGaZIxgdAPPZCBpWZUhZstbQ9dujtF/0DojoGJbON+01KU10nUTNmyI6JmmHYlKbXCoc+scOVTynrPiQSzk/YRUrxZaP9+BtbA7139NOCGDVZGkWdWa8JKQ2Ed1Hg6aubi8LLdpZiE2+pt6RUeshvWVz7t45M9dB0/hRpIaMmmczGR3fnlxmHVKl2dcAAAAASUVORK5CYII="
            alt
            class="hide-active"
          />
        </div>
      </div>
    </div>
    <div class="slidebar-wrap">
      <div class="slidebar-content">
        <ul class="slidebar-nav">
          <li class="s-n-item"><a href="/" class="activeColor">首页</a></li>
          <li class="s-n-item"><a href="/introduction">企业介绍</a></li>
          <li class="s-n-item">
            <a class="p-s-default">产品与服务</a>
            <ul class="s-b-sub-nav">
              <li><a href="/index#youshi">我们的优势</a></li>
              <li><a href="/index#product">产品功能</a></li>
              <li><a href="/index#serve">服务理念</a></li>
            </ul>
          </li>
          <li class="s-n-item"><a href="/news">行业动态</a></li>
          <li class="s-n-item"><a href="/aboutus">联系我们</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["aboutUs","isactive"],
  components: {},
  data() {
    return {
      isScroll: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.setHeadPosition, false);
  },
  methods: {
    setHeadPosition() {
      let scrollTop = document.documentElement.scrollTop;
      this.isScroll = scrollTop > 5 ? true : false;
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.setHeadPosition, true);
  },
};
</script>

<style lang="scss">
.header {
  height: 70px;
  position: fixed;
  z-index: 999;
  width: 100%;
}
.head {
  width: 92%;
  margin: 0 auto;
  position: relative;
}
.nav .nav-wrap {
  line-height: 70px;
  display: flex;
  justify-content: flex-end;
}
.nav .nav-wrap > li:hover .pssubnav {
  display: block;
}
.pssubnav {
  position: absolute;
  top: 55px;
  left: -16px;
  width: 114px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
  z-index: 99999;
  display: none;
  overflow: hidden;
  box-shadow: 0 0 5px 0 #dadfe4;
}
.pssubnav li {
  overflow: hidden;
  font-size: 0;
  float: left;
}
.pssubnav li,
.pssubnav li a {
  width: 100%;
  line-height: 33px;
  height: 33px;
}
.pssubnav li a {
  color: #333;
  text-align: center;
  font-size: 13px;
  display: inline-block;
}
.pssubnav li a:active,
.pssubnav li a:hover {
  color: #2b69fb;
  background-color: #f0f0f0;
}
.header-right {
  float: right;
  width: auto;
  height: 70px;
  line-height: 70px;
  margin-left: 60px;
}
.header-right a {
  margin: 0 8px;
  text-align: center;
}
.home-login {
  display: inline-block;
  width: 56px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
}
.home-login:hover {
  background: #fff;
  color: #2b69fb;
  font-weight: 400;
}
.free-use {
  display: inline-block;
  width: auto;
  padding: 0 16px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
}
.free-use:hover {
  background: #fff;
  color: #2b69fb;
  font-weight: 400;
}
.nav-wrap > li {
  margin: 0 25px;
  line-height: 70px;
  position: relative;
}
.nav-wrap a {
  height: 55px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
}
.product-server .down-arrow {
  display: inline-block;
  height: 10px;
  width: 10px;
  vertical-align: middle;
}
.nav-wrap a.active,
.nav-wrap a:hover {
  color: #fff;
  font-weight: 600;
  border-bottom: 3px solid #fff;
}
.product-server a.active,
.product-server a:hover {
  color: #fff;
  font-weight: 600;
  border-bottom: none;
}
.mobile-menu {
  display: none;
  float: right;
  height: 80px;
  line-height: 80px;
}
.mobile-menu img {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
.show-active {
  display: inline-block !important;
}
.hide-active {
  display: none !important;
}
.active-nav {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 3px 0 #f1f5f9;
}
.active-nav .show-active {
  display: none !important;
}
.active-nav .hide-active {
  display: inline-block !important;
}
.active-nav .nav-wrap a {
  height: 55px;
  text-decoration: none;
  color: #333;
  display: inline-block;
}
.active-nav .nav-wrap a.active,
.active-nav .nav-wrap a:hover {
  color: #2b69fb;
  font-weight: 600;
  border-bottom: 3px solid #2b69fb;
}
.active-nav .header-right {
  float: right;
  width: auto;
  height: 70px;
  line-height: 70px;
}
.active-nav .header-right a {
  margin: 0 8px;
  text-align: center;
}
.active-nav .product-server a.active,
.active-nav .product-server a:hover {
  color: #2b69fb;
  font-weight: 600;
  border-bottom: none;
}
.active-nav .home-login {
  display: inline-block;
  width: 56px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #2b69fb;
  color: #2b69fb;
}
.active-nav .home-login:hover {
  background: #2b69fb;
  color: #fff;
  font-weight: 400;
}
.active-nav .free-use {
  display: inline-block;
  width: auto;
  padding: 0 16px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  border: 1px solid #2b69fb;
  color: #2b69fb;
}
.active-nav .free-use:hover {
  background: #2b69fb;
  color: #fff;
  font-weight: 400;
}
@media screen and (max-width: 1050px) {
  .nav-wrap > li {
    margin: 0 11px;
  }
  .header-right {
    margin-left: 40px;
  }
}
@media screen and (max-width: 900px) {
  .nav-wrap > li {
    margin: 0 10px;
  }
  .header-right {
    margin-left: 20px;
  }
}
@media screen and (max-width: 850px) {
  .header {
    height: 70px;
    position: fixed;
    z-index: 999;
    width: 100%;
  }
  .head {
    width: 92%;
    margin: 0 auto;
    position: relative;
  }
  .logo {
    float: left;
    overflow: hidden;
  }
  .nav {
    float: right;
    text-align: center;
    position: relative;
    display: none;
  }
  .nav .nav-wrap {
    line-height: 80px;
  }
  .mobile-menu {
    display: block;
    height: 80px;
    line-height: 70px;
  }
  .mobile-menu img {
    width: 25px;
    height: 20px;
  }
  .header-right {
    display: none;
  }
}
.slidebar-wrap {
  background: rgba(0, 0, 0, 0.3);
}
.slidebar-wrap,
.slidebar-wrap .slidebar-content {
  position: fixed;
  right: 0;
  top: 0;
  width: 0;
  height: 100%;
  z-index: 999999;
}
.slidebar-wrap .slidebar-content {
  background: #fff;
  transition: width 0.5s;
}
.slidebar-wrap-active {
  width: 100%;
}
.slidebar-wrap-active .slidebar-content {
  transition: width 0.5s;
  position: fixed;
  right: 0;
  top: 0;
  width: 64%;
  height: 100%;
  background: #fff;
  z-index: 999999;
}
.slidebar-nav {
  width: 100%;
  padding: 12px 0;
}
.slidebar-nav li {
  text-align: left;
}
.slidebar-nav li,
.slidebar-nav li a {
  width: 100%;
  color: #051033;
  font-size: 16px;
}
.slidebar-nav li a {
  padding: 16px 24px;
  display: inline-block;
}
.slidebar-nav li .activeColor,
.slidebar-nav li a:active,
.slidebar-nav li a:hover {
  color: #2b69fb;
}
.slidebar-nav .p-s-default {
  display: inline-block;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABJElEQVRIS+3UMU7DMBTG8b+dhnYpqoCRuQxICFTEwBkQQw/Awk16HwYWJpBYEUiIhQuwoxYESBD8kBGRkppguymdkjGx/cv77GfFgh+1YI8GnHviTaT/G+lSbzAycIyYo2xye1FL6w7W0kSdgrQ/xm/7cP9u1yvtYau3e6KQIfAqog+zyfVs6DeGnbsl8Jzp1jqPV08OSHdvNU0+z4HtmdECBrwI6iAb31zmabmntA7qwdwK899Y3lxJdcdGEl5pAFYN2i8xaCD2NxiKRmB+0IdGYmFgFWrMXX70fzuNVT0cfpdO7SnCA4qNGCy8wvLptX268/PK6TPf7RReYRFNOmci0gc9LDa1D4uvsLyiBkwIUhwTX2GsMDW+AWsG6E5vIp17pF+hPaQdeBF4oAAAAABJRU5ErkJggg==)
    no-repeat 100%;
  background-size: 16px 16px;
  background-position: 92% 13px;
}
.slidebar-nav .p-s-active {
  display: inline-block;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABFklEQVRIS+3WsUoDMRzH8e8/nIIWtbj7Ai4iVFyFc3Jx8Xl8Exd3O0ifoVtVBAedHUWKLqfpJVLxqD3vrvePR6fLnOTD/5d/QoQlD1myRws2nngbaVmkBnAheasjjbq9I3B9EXmyaXLC28OrBlaBU0zwA6Dzg9xalxxr0NrgHOZ5RNgB1gEVWgvMVXZvU+LImD0Rd61FF4JFGO+jl2mk0dZBrEUrwSosa5QcemddEledaSlYBwtBC0ENpkX/gCGYBp0D/4PVRWfg9uFm5CbPAhvAd+tn3ah5SfLd6/FXk/HNWbbHrwp3V1e6a0OQD5v601BsVul+jJhLAxef49F5AaitI2z+wosftm35qhZsOtH2m9h4onwBYpGwHeK+154AAAAASUVORK5CYII=)
    no-repeat 100%;
  background-size: 16px 16px;
  background-position: 92% 16px;
}
.slidebar-nav .s-b-sub-nav {
  height: 0;
}
.slidebar-nav .s-b-sub-nav,
.slidebar-nav .s-b-sub-nav-active {
  width: 100%;
  overflow: hidden;
  text-align: center;
  background: #f7fafd;
}
.slidebar-nav .s-b-sub-nav-active {
  height: 100%;
  margin-top: 20px;
  transition: height 0.5s;
}
.container-wrap {
  overflow: hidden;
  background: none;
}
.container-wrap,
.home-content {
  width: 100%;
  height: auto;
}
.h-content-one {
  width: 100%;
  min-height: 400px;
  background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/89672ba.png)
      no-repeat 0 0,
    url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/a02a649.png)
      no-repeat 100% 100%,
    #f7fafd;
  background-size: 222px 374px, 300px 385px, 100% 100%;
}
.h-server-btn {
  display: inline-block;
  width: 128px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: linear-gradient(90deg, #2b69fb, #5396ff);
  border-radius: 20px;
  margin: 24px 0 8px;
}
.h-server-btn:hover {
  opacity: 0.8;
}
.h-content-wrap {
  max-width: 1000px;
  padding: 60px 0;
}
.h-content-wrap,
.h-one-content-wrap {
  height: auto;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.h-one-content-wrap {
  max-width: 1120px;
  padding: 60px;
}
.h-one-content-wrap .h-server {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.h-one-content-wrap .h-server .h-server-item {
  width: 40%;
  background: #fff;
  box-shadow: 0 0 20px 0 #e9f3fe;
  border-radius: 10px;
  padding: 40px;
  float: left;
  overflow: hidden;
  margin-bottom: 20px;
  -webkit-animation: transform 1s;
  animation: transform 1s;
}
.h-one-content-wrap .h-server .h-server-item .h-server-title {
  line-height: 33px;
  font-size: 24px;
  color: #051033;
  font-weight: 600;
  margin-top: 40px;
}
.h-one-content-wrap .h-server .h-server-item .h-server-desc {
  font-size: 16px;
  color: #051033;
  line-height: 22px;
  height: 44px;
  font-weight: 400;
  margin: 16px 0;
}
.h-one-content-wrap .h-server .h-server-item:nth-child(2n),
.h-one-content-wrap .h-server .h-server-item:nth-child(5) {
  margin-left: 20px;
}
.h-one-content-wrap .h-server .h-server-item:hover {
  box-shadow: 0 0 36px 0 #ced6df;
}
.h-title {
  font-size: 36px;
  color: #051033;
  font-weight: 600;
  margin: 52px 0 90px;
  line-height: 50px;
  letter-spacing: 5px;
}
.h-title-a {
  font-size: 0;
}
.h-title-a a {
  font-size: 36px;
  color: #051033;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 5px;
}
.empty {
  width: 100%;
  height: 68px;
}
.h-title-blue {
  display: inline-block;
  color: #2b69fb;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 5px;
}
.h-title-sub {
  display: inline-block;
  height: 45px;
  width: auto;
}
.h-title-sub-desc {
  max-width: 830px;
  font-size: 20px;
  color: #051033;
  font-weight: 500;
  line-height: 28px;
  margin: 32px auto 0;
}
.h-daily-case {
  width: 100%;
  height: auto;
  background: #f7fafd;
  padding: 40px 60px;
  margin: 56px 0 40px;
  overflow: hidden;
}
.h-daily-case .h-daily-case-leftIcon {
  width: 100%;
  height: auto;
  text-align: left;
}
.h-daily-case .h-daily-case-leftIcon img {
  display: inline-block;
  height: 21px;
  width: auto;
}
.h-daily-case .h-daily-case-title {
  font-size: 24px;
  color: #051033;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
  margin: 24px 0;
}
.h-daily-case .h-daily-desc {
  width: 100%;
  font-size: 20px;
  color: #051033;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin: 8px 0;
}
.h-daily-case .h-daily-number {
  width: 100%;
  height: auto;
  margin: 48px 0;
  padding: 0 9px 0 42px;
}
.h-daily-case .h-daily-numbe-item {
  width: auto;
  height: auto;
  float: left;
  overflow: hidden;
  font-size: 46px;
  font-weight: #051033;
  line-height: 55px;
}
.h-daily-case .h-daily-numbe-item:first-child {
  text-align: left;
  margin-right: 219px;
}
.h-daily-case .h-daily-numbe-item:nth-child(3) {
  float: right;
  text-align: right;
}
.h-daily-case .h-daily-numbe-item:nth-child(3) .h-daily-numbe-item-b,
.h-daily-case .h-daily-numbe-item:nth-child(3) .h-daily-numbe-item-t {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.h-daily-case .h-daily-numbe-item .h-daily-numbe-item-t {
  display: inline-block;
  height: 55px;
  width: auto;
  overflow: hidden;
}
.h-daily-case .h-daily-numbe-item .h-daily-numbe-item-t span {
  float: left;
  overflow: hidden;
  font-size: 46px;
  line-height: 55px;
  font-weight: 600;
}
.h-daily-case .h-daily-numbe-item .h-daily-numbe-item-t a {
  float: left;
  overflow: hidden;
  font-size: 24px;
  font-weight: #051033;
  line-height: 70px;
  font-weight: 600;
}
.h-daily-case .h-daily-numbe-item .h-daily-numbe-item-b {
  font-size: 18px;
  color: #051033;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.h-daily-case .h-daily-numbe-item .h-daily-numbe-item-att {
  display: inline-block;
  height: 160px;
  width: auto;
  overflow: hidden;
}
.h-daily-case .h-daily-numbe-item .h-daily-numbe-item-att img {
  display: inline-block;
  height: 160px;
  width: 130px;
}
.h-daily-case .h-daily-numbe-item .h-server-wrap {
  width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
}
.h-daily-case .h-daily-att-item {
  width: auto;
  height: auto;
  float: left;
  overflow: hidden;
  font-size: 46px;
  color: #051033;
  height: 196px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h-daily-case .h-daily-att-item:first-child {
  text-align: left;
  margin-right: 219px;
}
.h-daily-case .h-daily-att-item:nth-child(3) {
  float: right;
  text-align: right;
}
.h-daily-case .h-daily-att-item .h-daily-numbe-item-t img {
  display: inline-block;
  height: 160px;
  width: 130px;
}
.h-daily-case .h-daily-att-item .h-daily-numbe-item-b {
  font-size: 18px;
  color: #051033;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.h-daily-case .h-daily-att-item .h-server-wrap {
  width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
}
.h-att-case {
  background: #fff;
}
.h-content-safe {
  width: 100%;
  height: auto;
  background: #f7fafd;
}
.h-content-safe .h-safe-case {
  width: 100%;
}
.h-content-safe .h-safe-case dl {
  float: left;
  width: 320px;
  height: 513px;
  border-radius: 10px;
  padding: 8px 16px;
  background: #fff;
  overflow: hidden;
}
.h-content-safe .h-safe-case dl:nth-child(2n) {
  margin: 0 20px;
}
.h-content-safe .h-safe-case dl:hover {
  box-shadow: 0 0 32px 0 #dbe1e7;
}
.h-content-safe .h-safe-case dl dt {
  width: 288px;
  height: auto;
  margin-bottom: 6px;
}
.h-content-safe .h-safe-case dl dt img {
  display: inline-block;
  width: 288px;
  height: 296px;
}
.h-content-safe .h-safe-case dl dd h3 {
  font-size: 24px;
  color: #051033;
  font-weight: 600;
  margin: 16px 0;
}
.h-content-safe .h-safe-case dl dd .h-safe-desc {
  width: 100%;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  color: #051033;
  line-height: 28px;
}
.h-content-achievement {
  width: 100%;
  height: auto;
  background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/c5b04f7.png)
      no-repeat 0 100%,
    url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/42da830.png)
      no-repeat 100% 0,
    url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/4156443.png)
      no-repeat;
  background-size: 240px 181px, 201px 321px, 100% 100%;
}
.h-content-achievement .h-ach-title {
  font-size: 36px;
  color: #fff;
}
.h-content-achievement .h-ach-content-wrap {
  max-width: 1000px;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
  background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/9e423c2.png)
    no-repeat 100% 100%;
  background-size: 372px 132px;
  overflow: hidden;
}
.h-content-achievement .h-ach-number {
  width: 100%;
  overflow: hidden;
}
.h-content-achievement .h-ach-number-item {
  width: auto;
  height: auto;
  float: left;
  overflow: hidden;
  color: #fff;
  line-height: 76px;
}
.h-content-achievement .h-ach-number-item:first-child {
  text-align: center;
  margin-right: 265px;
}
.h-content-achievement .h-ach-number-item:nth-child(3) {
  float: right;
  text-align: center;
}
.h-content-achievement .h-ach-number-item .h-ach-numbe-item-t {
  display: inline-block;
  width: 100%;
  height: 76px;
  width: auto;
  overflow: hidden;
}
.h-content-achievement .h-ach-number-item .h-ach-numbe-item-t span {
  float: left;
  overflow: hidden;
  font-size: 76px;
  line-height: 76px;
  font-weight: 600;
  padding: 0 5px;
}
.h-content-achievement .h-ach-number-item .h-ach-numbe-item-t a {
  float: left;
  overflow: hidden;
  font-size: 32px;
  line-height: 100px;
  font-weight: 600;
}
.h-content-achievement .h-ach-number-item .h-ach-numbe-item-t a:first-child {
  font-size: 16px;
  line-height: 110px;
}
.h-content-achievement .h-ach-number-item .h-ach-numbe-item-b {
  width: 100%;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 25px;
  text-align: center;
}
.h-content-report {
  overflow: hidden;
  background: #fff;
}
.h-content-report,
.h-content-report .h-report-desc {
  width: 100%;
  height: auto;
}
.h-content-report .h-report-desc dl {
  width: 50%;
  height: 124px;
  float: left;
  overflow: hidden;
}
.h-content-report .h-report-desc dl:hover .h-report-desc,
.h-content-report .h-report-desc dl:hover h3 {
  text-decoration: underline;
}
.h-content-report .h-report-desc dl > dt {
  float: left;
  overflow: hidden;
  height: 124px;
  width: 124px;
  margin-right: 10px;
}
.h-content-report .h-report-desc dl > dt img {
  display: inline-block;
  height: 124px;
  width: 124px;
}
.h-content-report .h-report-desc dl > dd {
  float: left;
  overflow: hidden;
  height: 124px;
  width: 288px;
}
.h-content-report .h-report-desc dl > dd h3 {
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: #051033;
  line-height: 28px;
  margin: 6px 0;
  text-align: left;
}
.h-content-report .h-report-desc dl > dd .h-report-desc {
  width: auto;
  height: 66px;
  font-size: 16px;
  font-weight: 400;
  color: #7388a5;
  line-height: 22px;
  text-align: left;
  margin-top: 7px;
}
.h-content-choose {
  background: #f7fafd;
}
.h-content-bottom,
.h-content-choose {
  width: 100%;
  height: auto;
}
.h-content-bottom .h-content-adv {
  width: 100%;
  height: 272px;
  background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/ede4d7a.png)
    repeat-x;
  background-size: auto 272px;
  margin: 32px 0;
}
.h-content-bottom .h-content-left {
  width: 218px;
  height: 100%;
  float: left;
  overflow: hidden;
  opacity: 0.8;
}
.h-content-bottom .h-content-right {
  width: 218px;
  height: 100%;
  float: right;
  overflow: hidden;
  opacity: 0.8;
}
.h-content-bottom .h-content-bb {
  width: 100%;
  height: 444px;
  overflow: hidden;
  background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/867b7b7.png)
      no-repeat 0 100%,
    url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/ad11cfc.png)
      no-repeat bottom,
    url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/f48e3c7.png)
      no-repeat 100% 0;
  background-size: 258px 320px, 254px 115px, 313px 383px;
}
.h-content-bottom .h-content-bb .h-content-bb-title {
  width: 100%;
  text-align: center;
  font-size: 0;
  color: #051033;
  line-height: 50px;
  letter-spacing: 5px;
  margin: 174px 0 32px;
}
.h-content-bottom .h-content-bb .h-content-bb-title a {
  font-size: 36px;
  font-weight: 600;
  color: #2b69fb;
}
.h-content-bottom .h-content-bb .h-content-bb-title span {
  font-size: 36px;
  font-weight: 600;
}
.h-content-bottom .h-content-bb .h-user-btn {
  width: 100%;
  text-align: center;
}
.h-content-bottom .h-content-bb .h-user-btn a {
  display: inline-block;
  width: 150px;
  height: 46px;
  line-height: 46px;
  background: linear-gradient(90deg, #2b69fb, #5396ff);
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.h-content-bottom .h-content-bb .h-user-btn a:hover {
  background: #0e54f8;
}
.h-attendance {
  background: #f7fafd;
}
@media screen and (max-width: 1000px) {
  .container-wrap {
    overflow: hidden;
    background: none;
  }
  .container-wrap,
  .home-content {
    width: 100%;
    height: auto;
  }
  .h-content-one {
    width: 100%;
    min-height: auto;
    background: #f7fafd;
  }
  .h-server-btn {
    display: inline-block;
    width: 128px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #fff;
    background: linear-gradient(90deg, #2b69fb, #5396ff);
    border-radius: 20px;
    margin: 24px 0 8px;
  }
  .h-content-wrap {
    width: 92%;
    padding: 10px 0;
  }
  .h-content-wrap,
  .h-one-content-wrap {
    height: auto;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
  }
  .h-one-content-wrap {
    max-width: 1120px;
    padding: 0 30px;
  }
  .h-one-content-wrap .h-server {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .h-one-content-wrap .h-server .h-server-item {
    width: 92%;
    background: #fff;
    box-shadow: 0 0 20px 0 #e9f3fe;
    border-radius: 10px;
    padding: 40px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .h-one-content-wrap .h-server .h-server-item .h-server-title {
    line-height: 33px;
    font-size: 16px;
    color: #051033;
    font-weight: 600;
    margin-top: 32px;
  }
  .h-one-content-wrap .h-server .h-server-item .h-server-desc {
    font-size: 16px;
    color: #051033;
    line-height: 20px;
    height: 40px;
    font-weight: 400;
    margin: 8px 0;
  }
  .h-one-content-wrap .h-server .h-server-item:nth-child(2),
  .h-one-content-wrap .h-server .h-server-item:nth-child(5) {
    margin-left: 0;
  }
  .h-one-content-wrap .h-server .h-server-item:hover {
    transform: scale(1);
  }
  .h-title {
    font-size: 18px;
    color: #051033;
    font-weight: 600;
    margin: 32px 0 16px;
    line-height: 20px;
    letter-spacing: 2px;
  }
  .h-title-a {
    font-size: 0;
  }
  .h-title-a a {
    font-size: 18px;
    color: #051033;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 2px;
  }
  .empty {
    width: 100%;
    height: 68px;
  }
  .h-title-blue {
    display: inline-block;
    color: #2b69fb;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 2px;
  }
  .h-title-sub {
    display: inline-block;
    height: 20px;
    width: auto;
  }
  .h-title-sub-desc {
    max-width: 100%;
    font-size: 10px;
    color: #051033;
    font-weight: 500;
    line-height: 20px;
    margin: 18px auto 0;
  }
  .h-daily-case {
    width: 100%;
    height: auto;
    background: #f7fafd;
    padding: 20px 30px;
    margin: 28px 0 20px;
    overflow: hidden;
  }
  .h-daily-case .h-daily-case-leftIcon {
    width: 100%;
    height: auto;
    text-align: left;
  }
  .h-daily-case .h-daily-case-leftIcon img {
    display: inline-block;
    height: 10px;
    width: auto;
  }
  .h-daily-case .h-daily-case-title {
    font-size: 14px;
    color: #051033;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin: 12px 0;
  }
  .h-daily-case .h-daily-desc {
    width: 100%;
    font-size: 12px;
    color: #051033;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin: 4px 0;
  }
  .h-daily-case .h-daily-number {
    width: 100%;
    height: auto;
    margin: 24px 0;
    padding: 0 4px 0 21px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .h-daily-case .h-daily-numbe-item {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 23px;
    font-weight: #051033;
    line-height: 26px;
    text-align: center;
  }
  .h-daily-case .h-daily-numbe-item:first-child,
  .h-daily-case .h-daily-numbe-item:nth-child(2) {
    text-align: center;
    margin-right: 0;
    margin-bottom: 24px;
  }
  .h-daily-case .h-daily-numbe-item:nth-child(3) {
    float: right;
    text-align: right;
  }
  .h-daily-case .h-daily-numbe-item:nth-child(3) .h-daily-numbe-item-b,
  .h-daily-case .h-daily-numbe-item:nth-child(3) .h-daily-numbe-item-t {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }
  .h-daily-case .h-daily-numbe-item .h-daily-numbe-item-t {
    display: inline-block;
    height: 55px;
    width: auto;
    overflow: hidden;
  }
  .h-daily-case .h-daily-numbe-item .h-daily-numbe-item-t span {
    float: left;
    overflow: hidden;
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
  }
  .h-daily-case .h-daily-numbe-item .h-daily-numbe-item-t a {
    float: left;
    overflow: hidden;
    font-size: 18px;
    font-weight: #051033;
    line-height: 42px;
    font-weight: 600;
  }
  .h-daily-case .h-daily-numbe-item .h-daily-numbe-item-b {
    font-size: 12px;
    color: #051033;
    font-weight: 400;
    line-height: 12px;
  }
  .h-daily-case .h-daily-numbe-item .h-daily-numbe-item-att {
    display: inline-block;
    height: 160px;
    width: auto;
    overflow: hidden;
  }
  .h-daily-case .h-daily-numbe-item .h-daily-numbe-item-att img {
    display: inline-block;
    height: 160px;
    width: 130px;
  }
  .h-daily-case .h-daily-numbe-item .h-server-wrap {
    width: 100%;
    height: auto;
    text-align: center;
    overflow: hidden;
  }
  .h-daily-case .h-daily-att-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .h-daily-case .h-daily-att-item {
    width: 100%;
    height: auto;
    overflow: hidden;
    font-size: 23px;
    font-weight: #051033;
    text-align: center;
    margin-bottom: 20px;
  }
  .h-daily-case .h-daily-att-item .h-daily-numbe-item-t {
    margin-bottom: 10px;
  }
  .h-daily-case .h-daily-att-item:first-child,
  .h-daily-case .h-daily-att-item:nth-child(2) {
    text-align: center;
    margin-right: 0;
  }
  .h-daily-case .h-daily-att-item:nth-child(3) {
    float: right;
    text-align: right;
  }
  .h-daily-case .h-daily-att-item .h-daily-numbe-item-b {
    font-size: 12px;
    color: #051033;
    font-weight: 400;
    line-height: 12px;
  }
  .h-daily-case .h-daily-att-item .h-daily-numbe-item-att {
    display: inline-block;
    height: 160px;
    width: auto;
    overflow: hidden;
  }
  .h-daily-case .h-daily-att-item .h-daily-numbe-item-att img {
    display: inline-block;
    height: 160px;
    width: 130px;
  }
  .h-daily-case .h-daily-att-item .h-server-wrap {
    width: 100%;
    height: auto;
    text-align: center;
    overflow: hidden;
  }
  .h-att-case {
    background: #fff;
  }
  .h-content-safe {
    width: 100%;
    height: auto;
    background: #f7fafd;
  }
  .h-content-safe .h-safe-case {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    flex-direction: column;
  }
  .h-content-safe .h-safe-case dl {
    width: 100%;
    height: 255px;
    border-radius: 10px;
    padding: 8px 16px;
    background: #fff;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .h-content-safe .h-safe-case dl:nth-child(2) {
    margin: 0 0 20px;
  }
  .h-content-safe .h-safe-case dl:hover {
    box-shadow: none;
  }
  .h-content-safe .h-safe-case dl dt {
    width: auto;
    height: auto;
    margin-bottom: 6px;
  }
  .h-content-safe .h-safe-case dl dt img {
    display: inline-block;
    width: auto;
    height: 133px;
  }
  .h-content-safe .h-safe-case dl dd h3 {
    font-size: 16px;
    color: #051033;
    font-weight: 600;
    margin: 8px 0;
  }
  .h-content-safe .h-safe-case dl dd .h-safe-desc {
    width: 100%;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
    color: #051033;
    line-height: 14px;
  }
  .h-content-achievement {
    width: 100%;
    height: 372px;
    background: none;
    background: linear-gradient(90deg, #2b69fb, #5396ff);
  }
  .h-content-achievement .h-ach-title {
    font-size: 18px;
    color: #fff;
  }
  .h-content-achievement .h-ach-content-wrap {
    width: 92%;
    height: 372px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    background: none;
  }
  .h-content-achievement .h-ach-content-wrap,
  .h-content-achievement .h-ach-number {
    overflow: hidden;
    line-height: normal;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .h-content-achievement .h-ach-number {
    width: 100%;
    height: 100%;
  }
  .h-content-achievement .h-ach-number-item {
    width: 100%;
    overflow: hidden;
    color: #fff;
    line-height: normal;
  }
  .h-content-achievement .h-ach-number-item:first-child {
    text-align: center;
    margin-right: 0;
  }
  .h-content-achievement .h-ach-number-item:nth-child(3) {
    float: none;
    text-align: center;
  }
  .h-content-achievement .h-ach-number-item .h-ach-numbe-item-t {
    display: inline-block;
    width: 100%;
    height: auto;
    width: auto;
    overflow: hidden;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .h-content-achievement .h-ach-number-item .h-ach-numbe-item-t span {
    float: left;
    overflow: hidden;
    font-size: 36px;
    font-weight: 600;
    padding: 0 5px;
    line-height: normal;
  }
  .h-content-achievement .h-ach-number-item .h-ach-numbe-item-t a {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }
  .h-content-achievement .h-ach-number-item .h-ach-numbe-item-t a:first-child {
    font-size: 14px;
    line-height: normal;
  }
  .h-content-achievement .h-ach-number-item .h-ach-numbe-item-b {
    width: 100%;
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #fff;
    text-align: center;
  }
  .h-content-report {
    width: 100%;
    height: auto;
    overflow: hidden;
    background: #fff;
  }
  .h-content-report .h-report-desc {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .h-content-report .h-report-desc dl {
    width: 100%;
    height: 124px;
    display: flex;
    flex-direction: row;
  }
  .h-content-report .h-report-desc dl > dt img {
    display: inline-block;
    height: 95px;
    width: 95px;
  }
  .h-content-report .h-report-desc dl > dd {
    float: left;
    overflow: hidden;
    height: 124px;
    width: 288px;
  }
  .h-content-report .h-report-desc dl > dd h3 {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #051033;
    line-height: 20px;
    margin: 6px 0;
    text-align: left;
  }
  .h-content-report .h-report-desc dl > dd .h-report-desc {
    width: auto;
    font-size: 12px;
    font-weight: 400;
    color: #7388a5;
    line-height: 22px;
    text-align: left;
    margin-top: 7px;
  }
  .h-content-choose {
    background: #f7fafd;
  }
  .h-content-bottom,
  .h-content-choose {
    width: 100%;
    height: auto;
  }
  .h-content-bottom .h-content-adv {
    width: 100%;
    height: 120px;
    background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/ede4d7a.png)
      repeat-x;
    background-size: auto 120px;
    margin: 0;
  }
  .h-content-bottom .h-content-left {
    width: 64px;
    height: 100%;
    float: left;
    overflow: hidden;
    opacity: 0.8;
  }
  .h-content-bottom .h-content-right {
    width: 64px;
    height: 100%;
    float: right;
    overflow: hidden;
    opacity: 0.8;
  }
  .h-content-bottom .h-content-bb {
    width: 100%;
    height: auto;
    overflow: hidden;
    background: none;
    padding: 23px 0;
  }
  .h-content-bottom .h-content-bb .h-content-bb-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #051033;
    line-height: normal;
    letter-spacing: 1px;
    margin: 24px 0;
  }
  .h-content-bottom .h-content-bb .h-content-bb-title a {
    font-size: 16px;
    font-weight: 600;
    color: #2b69fb;
  }
  .h-content-bottom .h-content-bb .h-content-bb-title span {
    font-size: 16px;
    font-weight: 600;
  }
  .h-content-bottom .h-content-bb .h-user-btn {
    width: 100%;
    text-align: center;
  }
  .h-content-bottom .h-content-bb .h-user-btn a {
    display: inline-block;
    width: 150px;
    height: 46px;
    line-height: 46px;
    background: linear-gradient(90deg, #2b69fb, #5396ff);
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
  .h-content-bottom .h-content-bb .h-user-btn a:hover {
    background: #0e54f8;
  }
}
.banner-box {
  width: 100%;
  // height: 571px;
  position: relative;
}
.banner-box .banner-desc {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 8;
}
.banner-box .banner-desc .banner-content {
  max-width: 1000px;
  height: auto;
  margin: 0 auto;
  transform: translateY(60%);
}
.banner-box .banner-desc .banner-content .banner-content-title {
  font-size: 52px;
  color: #fff;
  font-weight: 600;
  line-height: 72px;
}
.banner-box .banner-desc .banner-content .banner-content-subtitle {
  font-size: 28px;
  color: #fff;
  font-weight: 400;
  line-height: 38px;
  margin-top: 4px;
}
.banner-box .banner-desc .banner-content .banner-content-bottom {
  margin-top: 40px;
}
.banner-box .banner-desc .banner-content .banner-content-bottom a {
  display: inline-block;
  text-align: center;
  width: 150px;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 5px;
}
.banner-box .banner-desc .banner-content .banner-content-bottom a:first-child {
  margin-right: 20px;
  background-size: 24px 24px;
}
.banner-box
  .banner-desc
  .banner-content
  .banner-content-bottom
  .banner-btn-active,
.banner-box .banner-desc .banner-content .banner-content-bottom a.active,
.banner-box .banner-desc .banner-content .banner-content-bottom a:hover {
  background: #fff;
  color: #2b69fb;
}
// 更改1
.banner-box #parent {
  // height: 571px;
  width: 100%;
  background: url("../assets/images/header_top.jpg") no-repeat;
  background-size: cover;
}
.banner-box #parent .swiper-container {
  visibility: hidden;
  z-index: -1;
  position: absolute;
}
.banner-box #parent:hover .swiper-container {
  visibility: visible;
}
.banner-box #parent .swiper-slide {
  width: 100%;
  // height: 571px;
}
.banner-box #parent .swiper-slide .swiper-silde-item {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.banner-box #parent .swiper-slide .home-banner {
  display: inline-block;
  width: auto;
  height: 353px;
  position: absolute;
  top: 19%;
  right: 20%;
  z-index: 1;
}
.banner-box #parent .swiper-slide .banner02 {
  text-align: center;
}
.banner-box #parent .swiper-slide .banner02 .home-banner {
  height: 400px;
  max-height: 80%;
  left: 50%;
  transform: translateX(-50%);
  right: auto;
  top: 50px;
}
.banner-box #parent .swiper-slide .swipercontent {
  width: 1000px;
  // height: 571px;
  color: transparent;
  background: none;
}
.banner-box #parent .swiper-pagination-wrap {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.banner-box #parent .swiper-pagination-inner {
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.banner-box #parent .swiper-pagination {
  position: absolute;
  bottom: 120px;
  left: 0;
  border-radius: none;
  text-align: left;
  border-radius: 0;
}
.banner-box #parent .swiper-pagination-bullet {
  display: inline-block;
  width: 32px;
  height: 3px;
  border-radius: 0;
  margin: 0 8px;
}
.banner-box #parent .swiper-pagination-bullet-active {
  display: inline-block;
  width: 32px;
  height: 3px;
  background: #fff;
  border-radius: none;
}
.banner-box .home-sub-nav {
  width: 100%;
  height: 92px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
}
.banner-box .home-sub-nav .home-sub-nav-wrap {
  max-width: 1000px;
  height: 92px;
  margin: 0 auto;
}
.banner-box .home-sub-nav .home-sub-nav-wrap ul {
  width: 100%;
  line-height: 92px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 127px;
}
.banner-box .home-sub-nav .home-sub-nav-wrap ul li {
  display: inline-block;
  line-height: 92px;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  text-align: center;
}
.banner-box .home-sub-nav .home-sub-nav-wrap ul li img {
  margin-right: 8px;
}
.banner-box .home-sub-nav .home-sub-nav-wrap ul li .xiaomi,
.banner-box .home-sub-nav .home-sub-nav-wrap ul li .zhifubaoicon {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
}
.banner-box .home-sub-nav .home-sub-nav-wrap ul li .shunwei {
  display: inline-block;
  width: 26px;
  height: 40px;
  vertical-align: middle;
}
.banner-box .home-sub-nav .home-sub-nav-wrap ul .lined {
  height: 20px;
  width: 2px;
  background: #fff;
}
@media screen and (max-width: 1000px) {
  .banner-box {
    width: 100%;
    height: 361px;
    position: relative;
  }
  .banner-box .banner-desc {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(10%);
    z-index: 8;
  }
  .banner-box .banner-desc .banner-content {
    max-width: 1000px;
    height: auto;
    margin: 0 auto;
    text-align: center;
  }
  .banner-box .banner-desc .banner-content .banner-content-title {
    font-size: 32px;
    color: #fff;
    font-weight: 600;
    line-height: 36px;
  }
  .banner-box .banner-desc .banner-content .banner-content-subtitle {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    line-height: 19px;
    margin-top: 2px;
  }
  .banner-box .banner-desc .banner-content .banner-content-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  .banner-box .banner-desc .banner-content .banner-content-bottom a {
    display: inline-block;
    text-align: center;
    font-size: 16px;
    color: #2b69fb;
    width: 188px;
    height: 41px;
    border-radius: 3px;
    line-height: 41px;
    background: #fff;
  }
  .banner-box
    .banner-desc
    .banner-content
    .banner-content-bottom
    a:first-child {
    margin-right: 0;
    margin-bottom: 8px;
    background-size: 24px 24px;
  }
  .banner-box
    .banner-desc
    .banner-content
    .banner-content-bottom
    .banner-btn-active,
  .banner-box .banner-desc .banner-content .banner-content-bottom a.active,
  .banner-box .banner-desc .banner-content .banner-content-bottom a:hover {
    background: #fff;
    color: #2b69fb;
  }
  .banner-box #parent {
    height: 361px;
    width: 100%;
  }
  .banner-box #parent .swiper-container {
    visibility: hidden;
    z-index: -1;
    position: absolute;
  }
  .banner-box #parent:hover .swiper-container {
    visibility: visible;
  }
  .banner-box #parent .swiper-slide {
    width: 100%;
    height: 361px;
  }
  .banner-box #parent .swiper-slide .swiper-silde-item {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .banner-box #parent .swiper-slide .home-banner {
    display: none;
  }
  .banner-box #parent .swiper-slide .banner02 .home-banner {
    display: inline-block;
    max-width: 90%;
    height: auto;
    top: 40px;
  }
  .banner-box #parent .swiper-slide .swipercontent {
    width: 1000px;
    height: 361px;
    color: transparent;
    background: none;
  }
  .banner-box #parent .swiper-pagination-wrap {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
  }
  .banner-box #parent .swiper-pagination-inner {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .banner-box #parent .swiper-pagination {
    position: absolute;
    bottom: 50px;
    left: 0;
    border-radius: none;
    text-align: left;
  }
  .banner-box #parent .swiper-pagination-bullet,
  .banner-box #parent .swiper-pagination-bullet-active {
    display: inline-block;
    width: 32px;
    height: 3px;
    border-radius: none;
  }
  .banner-box #parent .swiper-pagination-bullet-active {
    background: #fff;
  }
  .banner-box .home-sub-nav {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.3);
  }
  .banner-box .home-sub-nav .home-sub-nav-wrap {
    max-width: 1000px;
    height: 40px;
    margin: 0 auto;
  }
  .banner-box .home-sub-nav .home-sub-nav-wrap ul {
    width: 100%;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
  }
  .banner-box .home-sub-nav .home-sub-nav-wrap ul li {
    display: inline-block;
    line-height: 40px;
    font-size: 10px;
    color: #fff;
    font-weight: 400;
    text-align: center;
  }
  .banner-box .home-sub-nav .home-sub-nav-wrap ul li img {
    margin-right: 8px;
  }
  .banner-box .home-sub-nav .home-sub-nav-wrap ul li .xiaomi,
  .banner-box .home-sub-nav .home-sub-nav-wrap ul li .zhifubaoicon {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  .banner-box .home-sub-nav .home-sub-nav-wrap ul li .shunwei {
    display: inline-block;
    width: 14px;
    height: 20px;
    vertical-align: middle;
  }
  .banner-box .home-sub-nav .home-sub-nav-wrap ul .lined {
    height: 10px;
    width: 1px;
    background: #fff;
  }
}
.banner-box2,
.banner-box2 #parent2 {
  width: 100%;
  height: 440px;
}
.banner-box2 #parent2 .swiper-wrapper {
  width: 100%;
  padding-top: 70px;
}
.banner-box2 #parent2 .swiper-button-prev {
  background-image: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/e1f621c.png);
  width: 30px;
  height: 30px;
  background-size: contain;
}
.banner-box2 #parent2 .swiper-button-next {
  background-image: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/bb59f15.png);
  width: 30px;
  height: 30px;
  background-size: contain;
}
.banner-box2 #parent2 .swiper-slide {
  width: 359px;
  height: 285px;
  border-radius: 10px;
  text-align: center;
  transition: 0.2s;
  background: #fff;
  border-radius: 7px;
  opacity: 0.6;
  transform: scale(0.7);
}
.banner-box2 #parent2 .swiper-slide .h-silde-leftIcon {
  width: 100%;
  margin: 20px 28px;
  text-align: left;
}
.banner-box2 #parent2 .swiper-slide .h-silde-leftIcon img {
  display: inline-block;
  height: 24px;
  width: 24px;
}
.banner-box2 #parent2 .swiper-slide .h-silde-desc {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  line-height: 25px;
  margin: 0 42px 52px;
}
.banner-box2 #parent2 .swiper-slide .h-slide-company {
  font-size: 12px;
  color: #000;
  font-weight: 400;
  margin: 8px 0;
}
.banner-box2 #parent2 .swiper-slide .h-slide-boss {
  font-size: 18px;
  font-weight: 500;
}
.banner-box2 #parent2 .swiper-slide-active {
  color: #333;
  background: #fff;
  box-shadow: 0 0 24px 0 #dfe8f1;
  border-radius: 10px;
  transform: scale(1.3);
  opacity: 1;
  z-index: 1;
}
.banner-box2 #parent2 .swiper-container {
  visibility: hidden;
  z-index: -1;
  position: absolute;
}
.banner-box2 #parent2:hover .swiper-container {
  visibility: visible;
}
.banner-box2 #parent2 .swiper-pagination-wrap {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
}
.banner-box2 #parent2 .swiper-pagination-inner {
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.banner-box2 #parent2 .swiper-pagination {
  position: absolute;
  bottom: 128px;
  left: 0;
  border-radius: none;
  text-align: left;
}
.banner-box2 #parent2 .swiper-pagination-bullet,
.banner-box2 #parent2 .swiper-pagination-bullet-active {
  display: inline-block;
  width: 32px;
  height: 3px;
  border-radius: 0;
}
.banner-box2 #parent2 .swiper-pagination-bullet-active {
  background: #fff;
}
#parent3 {
  display: none;
}
@media screen and (max-width: 1000px) {
  #parent2 {
    display: none;
  }
  #parent3 {
    display: block;
    height: 400px;
    width: 100%;
    padding-top: 50px;
  }
  #parent3 .swiper-slide {
    width: 92%;
    height: 285px;
    border-radius: 10px;
    text-align: center;
    transition: 0.2s;
    background: #fff;
    border-radius: 7px;
    opacity: 0.6;
  }
  #parent3 .swiper-slide .h-silde-leftIcon {
    width: 100%;
    margin: 20px 28px;
    text-align: left;
  }
  #parent3 .swiper-slide .h-silde-leftIcon img {
    display: inline-block;
    height: 19px;
    width: 19px;
  }
  #parent3 .swiper-slide .h-silde-desc {
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    line-height: 25px;
    margin: 0 42px 52px;
  }
  #parent3 .swiper-slide .h-slide-company {
    font-size: 12px;
    color: #000;
    font-weight: 400;
    margin: 8px 0;
  }
  #parent3 .swiper-slide .h-slide-boss {
    font-size: 18px;
    font-weight: 500;
  }
  #parent3 .swiper-slide-active {
    color: #333;
    background: #fff;
    box-shadow: 0 0 24px 0 #dfe8f1;
    border-radius: 10px;
    transform: scale(1);
    opacity: 1;
    z-index: 1;
  }
  #parent3 .swiper-container {
    visibility: hidden;
    z-index: -1;
    position: absolute;
  }
  #parent3:hover .swiper-container {
    visibility: visible;
  }
  #parent3 .swiper-pagination-wrap {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
  }
  #parent3 .swiper-pagination-inner {
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  #parent3 .swiper-pagination {
    position: absolute;
    bottom: 128px;
    left: 0;
    border-radius: none;
    text-align: left;
  }
  #parent3 .swiper-pagination-bullet,
  #parent3 .swiper-pagination-bullet-active {
    display: inline-block;
    width: 32px;
    height: 3px;
    border-radius: none;
  }
  #parent3 .swiper-pagination-bullet-active {
    background: #fff;
  }
}
.video-wrap {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.video-wrap,
.video-wrap .full-video {
  position: fixed;
  z-index: 99999;
  text-align: center;
}
.video-wrap .full-video {
  left: 50%;
  top: 50%;
  width: 60%;
  height: auto;
  transform: translate(-50%, -50%);
  background: none;
  border-radius: 5px;
}
.video-wrap .full-video .full-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
}
.video-wrap .full-video .closebtn {
  position: absolute;
  right: 0;
  top: -5px;
  display: inline-block;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  .video-wrap {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .video-wrap,
  .video-wrap .full-video {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    text-align: center;
  }
  .video-wrap .full-video {
    left: 50%;
    top: 50%;
    overflow: hidden;
    padding-top: 50%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0;
  }
  .video-wrap .full-video .full-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
  }
  .video-wrap .full-video .closebtn {
    position: absolute;
    right: 0;
    top: -5px;
    display: inline-block;
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
}
.full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.full-screen .full-s-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.full-screen .f-s-dialog {
  width: 493px;
  height: 533px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 24px 0 rgba(31, 31, 31, 0.5);
  border-radius: 10px;
  background: #fff;
  z-index: 999;
  transform: translate(-50%, -50%);
}
.full-screen .f-s-dialog .f-s-d-top {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 163px;
  background: url(//cdn.julanling.com/pro/www/saas-office-web/20210928391a254/img/e062776.png)
    no-repeat;
  background-size: 100% 100%;
}
.full-screen .f-s-dialog .f-s-d-top p {
  font-size: 16px;
  font-weight: 400;
  color: #051033;
  line-height: 22px;
}
.full-screen .f-s-dialog .f-s-d-top h3 {
  font-size: 32px;
  font-weight: 600;
  color: #051033;
  line-height: 45px;
}
.full-screen .f-s-dialog .f-s-d-top .popcloseBtn {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.f-s-d-bottom {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.f-s-d-bottom p {
  font-size: 16px;
  font-weight: 400;
  color: #051033;
  line-height: 22px;
}
.f-s-d-bottom p:first-child {
  margin-top: 24px;
}
.f-s-d-bottom .contactwx {
  display: inline-block;
  height: 218px;
  width: 218px;
}
.f-s-d-bottom .contactbutton {
  display: inline-block;
  height: 38px;
  width: auto;
  cursor: pointer;
}
.slidebar-p {
  position: fixed;
  right: 20px;
  bottom: 120px;
  width: 156px;
  height: auto;
  overflow: hidden;
  z-index: 9999;
  text-align: center;
  background: none;
}
.slide-top {
  width: 100%;
  height: 217px;
  background-size: 100% 100%;
  position: relative;
}
.slide-top p {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 17px;
  margin-top: 16px;
}
.server-head {
  text-align: center;
}
.server-head img {
  display: inline-block;
  width: 72px;
  height: auto;
  margin-top: 24px;
}
.m-p-number a {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}
.concatus-btn,
.m-p-number a {
  display: inline-block;
  color: #fff;
}
.concatus-btn {
  text-align: center;
  width: 108px;
  height: 32px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  border: 1px solid #fff;
  margin-top: 16px;
}
.moblie-btn {
  display: none;
}
.closebtn {
  display: inline-block;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.slide-wechat {
  width: 100%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 40px 0 #f1f5f9;
  border-radius: 10px;
  padding: 10px 0 8px;
  margin: 8px 0;
}
.slide-wechat img {
  display: inline-block;
  width: 96px;
  height: 96px;
}
.slide-wechat p {
  font-size: 12px;
  color: #051033;
  line-height: 17px;
  margin: 8px;
}
.gotop,
.slide-wechat p {
  font-weight: 400;
  text-align: center;
}
.gotop {
  display: inline-block;
  line-height: 45px;
  width: 156px;
  height: 45px;
  background: #55a1fd;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}
.moblie-slider {
  display: none;
}
@media screen and (max-width: 1000px) {
  .slidebar-p {
    position: fixed;
    right: 0;
    bottom: 10px;
    width: 30px;
    height: auto;
    overflow: scroll;
    z-index: 9999;
    text-align: center;
    background: red;
  }
  .pc-slider {
    display: none;
  }
  .moblie-slider {
    display: block;
    position: fixed;
    right: 0;
    top: 50%;
    width: 74px;
    overflow: hidden;
    z-index: 9999;
    text-align: center;
    background: none;
    height: auto;
  }
  .moblie-slider img {
    display: inline-block;
    width: 74px;
    height: auto;
  }
  .moblie-slider img:nth-child(2) {
    transform: translateY(-17px);
  }
}
.footer[data-v-38cb5c44] {
  width: 100%;
  height: auto;
  background: #061845;
  padding: 40px 0;
  p {
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}
</style>
