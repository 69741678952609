import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: index,
        meta:{
            title:"首页",
            keep: false,
        }
    },
    {
        path: '/index',
        component: index,
        meta:{
            title:"首页",
            keep: false,
        }
    },
    {
        path: '/news',
        name: 'News',
        component: resolve => require(['@/views/news/news.vue'],resolve),
        meta:{
            title:"新闻",
            keep: false,
        }
    },
    // {
    //     path: '/product',
    //     name: 'Product',
    //     component: resolve => require(['@/views/product/index.vue'],resolve),
    //     meta:{
    //         title:"产品与服务",
    //         keep: false,
    //     }
    // },
    // {
    //     path: '/zhaopin',
    //     name: 'Zhaopin',
    //     component: resolve => require(['@/views/product/zhaopin.vue'],resolve),
    //     meta:{
    //         title:"招聘",
    //         keep: false,
    //     }
    // },
    {
        path: '/aboutus',
        name: 'Aboutus',
        component: resolve => require(['@/views/aboutUs/index.vue'],resolve),
        meta:{
            title:"关于我们",
            keep: false,
        }
    },
    // {
    //     path: '/service',
    //     name: 'Service',
    //     component: resolve => require(['@/views/service/index.vue'],resolve),
    //     meta:{
    //         title:"服务商合作",
    //         keep: false,
    //     }
    // },
    // {
    //     path: '/freeuse',
    //     name: 'Freeuse',
    //     component: resolve => require(['@/views/product/freeuse.vue'],resolve),
    //     meta:{
    //         title:"产品预约演示",
    //         keep: false,
    //     }
    // },
    {
        path: '/introduction',
        name: 'Introduction',
        component: resolve => require(['@/views/product/introduction.vue'],resolve),
        meta:{
            title:"产品预约演示",
            keep: false,
        }
    },
    
]

const router = new VueRouter({
    mode: 'history',   //去掉路径中的#
    base: process.env.BASE_URL,
    routes
})

export default router
