import Vue from 'vue'
import App from './App.vue'
import router from './router'

import "./assets/css/pub.scss"
import "./assets/iconfont/iconfont.css"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
    // console.log(to,from)
    // if (store.state.token !== "" && store.state.token !== null) {  // 通过vuex state获取当前的token是否存在

        //判断是否需要缓存
        /*if(to.path === '/searchVillage' && from.path === '/index_visitorApplication'){
            to.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
            next();
        }else {
            to.meta.keepAlive = false;  // 让 列表页 即不缓存，刷新
            next();
        }*/

    // }else {
    //     next({
    //         path: '/login',
    //         query: {redirect: from.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
    //     })
    // }
// })
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
